@import 'canopy-vis-base';

.multi-plot-axis-renderer {
  .axis-rect {
    stroke: $shade-color-1;
    stroke-width: 1px;

    fill: $shade-color-1;
  }

  .axis-label {
    fill: var(--bs-body-color);
    font: $chart-font;
    cursor: pointer;
  }

  .axis-grid-lines {

    path,
    .tick>line {
      stroke: $shade-color-3;
      opacity: 0.5;
      stroke-dasharray: 1, 2;
      shape-rendering: crispEdges;
    }

    .tick>text {
      visibility: hidden;
    }
  }

  .axis {
    .tick{
      line{
        stroke: var(--bs-body-color);
      }
      text{
        fill: var(--bs-body-color);
      }
    }
    path,
    line {
      stroke: var(--bs-body-color);
    }
  }
}
