@import "bootstrap/scss/mixins/_caret.scss";

table.worksheet-table {

  $primary-font-size: 0.8rem;


  &>thead>tr>th {
    font-size: $primary-font-size;
  }

  // ===== Start 100% Height Sizing =====
  &>tbody>tr>td {
    // https://stackoverflow.com/a/3542470/37725
    height: 20px;

    .main-menu, .alternative-menu {
      height: 100%;
    }

    .btn-group, .main-menu>.btn-group, .alternative-menu>.btn-group {
      height: 100%;
      button {
        height: 100%;
      }
    }

    .cell-content {
      height: 100%;
      font-size: $primary-font-size;
    }
  }
  @-moz-document url-prefix() {
    &>tbody>tr>td {
      height: 100%;
    }
  }
  // ===== End 100% Height Sizing =====

  &.wrapping-worksheet-table {
    &>tbody>tr>td {
      min-width: 120px;

      .btn-group button {
        white-space: normal;
      }
    }
  }

  &>tbody>tr>td {
    position: relative;

    padding: 1px;
    border-left: 1px solid var(--bs-border-color);

    &:first-child {
      border-left: none;
    }

    &.is-selected {
      border: 1px solid $gray-500;
      background-color: $shade-color-1;
    }

    &.matches-selected {
      background-color: $shade-color-2;
    }

    &:focus {
      outline: none;
      &.config-cell, &.study-cell {
        box-shadow: inset 0 0 3px $gray-400;
      }
    }
    &:hover {
      background-color: $shade-color-3;

      .dropdown-toggle {
        visibility: visible !important;
      }

      button {
        &::after,
        &::before {
          visibility: visible !important;
        }
      }
    }

    .cell-name {
    }

    .cell-reference-id {
      display: inline-block;
      max-width: 3rem;
      overflow: hidden;
      font-size: 0.5rem;
      color: $gray-400;
    }

    .validity-icon {
      color: $gray-400;
      font-size: 0.4rem;
      vertical-align: top;
      &.valid-icon, .valid-icon {
        color: $success;
      }
      &.invalid-icon, .invalid-icon {
        color: $warning;
      }
      position: absolute;
      left: 1px;
      top: 1px;
    }

    .support-session-open-icon {
      color: $brand-warning;
      margin-left: 0.25rem;
    }

    .reference-count, .sim-version, .creation-date {
      color: $gray-500;
      font-size: 0.6rem;
      position: absolute;
      font-family: $font-family-monospace;
    }

    .reference-count {
      left: 1px;
      bottom: -2px;
    }

    .sim-version {
      right: 1px;
      top: 0;
    }

    .creation-date {
      right: 1px;
      bottom: -2px;
    }

    .simulation-exists-icon, .row-number {
      position: absolute;
      left: 1px;
      top: 1px;
      font-size: 0.6rem;
      color: $gray-600;
    }

    $cell-content-padding: 0.375rem 0.75rem 0.2rem;
    .cell-content {
      padding: $cell-content-padding;
    }
  }

  .study-type {
    color: $gray-400;
    margin-right: 1rem;
  }

  .study-progress {
    color: $success;

    &.has-failed-jobs {
      color: $danger;
    }
  }

  .study-information, .study-type {
    white-space: nowrap;
  }

  .read-only-icon {
    font-size: 0.7rem;
    color: $gray-500;
  }

  &>thead>tr>th {
    position:sticky;
    top: -8px;
    z-index: 1000;
  }
}

.worksheet-dock {
  table.worksheet-table>thead>tr>th {
    top: -24px;
  }
}

.button-no-cursor {
  cursor: default !important;
}
