
@import "_canopy-base";
@import "bootstrap/scss/bootstrap.scss";

.container-fluid{
  max-width: $content-max-width;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  &.card-header{
    margin-bottom: 0;
  }
}

h1, .h1 {
  font-size: 1.4rem;
}
h2, .h2 {
  font-size: 1.2rem;
}
h3, .h3 {
  font-size: 1rem;
  font-weight: bold;
}
h4, .h4 {
  font-size: 1rem;
}
h5, .h5 {
  font-size: 1rem;
}
h6, .h6 {
  font-size: 1rem;
}

@include color-mode(light){
  --dialog-background-opacity: 0.5;
}
@include color-mode(dark){
  --dialog-background-opacity: 0.12;
}


.dialog-container {
  z-index: 1010;
  overflow: auto;
  background-color: rgba(var(--bs-body-color-rgb), var(--dialog-background-opacity));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.dialog-is-open {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  @include media-breakpoint-up(sm) {
    position: absolute;
  }
}

@mixin remove-focus($color, $background, $border) {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:focus,
  &.focus,
  &:active,
  &.active {
    box-shadow: none;

    :hover {
      box-shadow: none;
    }
  }
}


@include color-mode(light){
  .btn-secondary {
    @include button-variant($body-bg, #bbb, $hover-background:$body-secondary-bg);
  }
}
@include color-mode(dark){
  .btn-secondary {
    @include button-variant($body-bg-dark, #555, $body-color-dark, $hover-background: $body-secondary-bg-dark);
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include remove-focus($value, darken($value, 7.5%), $value);
  }
}

.btn-default {
  @extend .btn-secondary;
}

.card {
  margin-bottom: 0.75rem; // Returns margin from alpha 2.
}

.alert {
  p:last-child {
    margin-bottom: 0;
  }
}

//Bootstrap 5 adjustments
.table {
  --#{$prefix}table-color-type: none;
  --#{$prefix}table-bg-type: none;
  --#{$prefix}table-color-state: none;
  --#{$prefix}table-bg-state: none;
}

//three doesn't support rgba!
:root[data-bs-theme="light"]{
  --grid-centreline-colour: #DDDDDD;
  --grid-colour: #EEEEEE;
}
:root[data-bs-theme="dark"]{
  --grid-centreline-colour: #444444;
  --grid-colour: #222222;
}
