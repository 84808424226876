@import '../tests/bootstrap-automated-tests';
@import '../tests/components/legend-renderer.bootstrap';
@import '../tests/components/sliders.bootstrap';
@import '../tests/components/source-labels-renderer.bootstrap';

body {
  padding-top: 10px;
}

ul {
  list-style-type: disc;
}

hr {
  color: black;
  margin: 50px 0;
}

.automated-tests-link {
  margin-top: 2rem;
  text-align: center;
}
