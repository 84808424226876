@import 'canopy-vis-base';
@import 'canopy-vis-charts';

.canvas-viewer {
  position: relative;

  >canvas,
  >svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:focus {
    outline: 0;
  }
}

.grid-stack .grid-stack-placeholder>.placeholder-content {
  border: 1px dashed #063559;
}

.grid-stack>.grid-stack-item>.ui-resizable-s {
  height: 5px;
}

.grid-stack-item-content {
  overflow: hidden;

  //padding: 0 0.5rem !important;
  // &.ui-resizable-resizing {
    //padding-right: 0 !important; // Improve a rendering issue.
  // }

  .hidden-button {
    display: none;
  }

  .active-button {
    color: #1e90ff !important;
  }

  .cs-grid-stack-drag-area {
    cursor: move;
  }

  .card {
    height: 100%;
    overflow: hidden;
    margin: 0;

    .card-header {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .viewer-title {
        font-size: 1.2rem;
        margin-bottom: 0;

        small {
          color: grey;
          margin-left: 0.5rem;
        }

        button {
          padding: 0.1rem 0.5rem;
          margin-top: -1rem;
          margin-bottom: -1rem;
          margin-left: 0.5rem;
        }
      }

      .move-indicator {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        padding-top: 0.5rem;
        display: none;
      }
    }

    .card-block {
      padding: 0;
      position: relative;
    }

    .card-buttons {
      position: absolute;
      top: 0;
      left: 0;

      $border: 1px solid rgba(0, 0, 0, 0.121569);

      .card-buttons-toggle {
        padding: 7px;
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 1rem;
        //border-bottom-right-radius: 1rem;
        border-top-left-radius: 0;
        border-right: $border;
        border-bottom: $border;
        background: var(--bs-card-cap-bg);

        .fa {
          font-size: 15px;
        }
      }

      .card-buttons-toggle-target {
        .button-row {
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;
        }

        button {
          margin-right: 0.25rem;

          .fa {
            margin-left: -0.25rem;
            margin-right: 0.25rem;
          }
        }

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0.5rem;
        background: rgba(var(--bs-body-bg-rgb), 0.6);
        border-bottom: $border;
        //background: rgba(255, 255, 255, 0.5);
        //border: 1px solid lightgray;
      }

      &.card-buttons-hidden {
        .card-buttons-toggle-target {
          display: none;
        }
      }

      &.card-buttons-shown {
        right: 0;

        // .card-buttons-toggle-target {}
      }
    }

    .viewer-content {
      position: relative;
    }

    .loading-indicator {
      font-size: 0.8rem;
      text-align: center;
      margin-top: 2rem;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }

    .viewer-error {
      p {
        margin: 0;
      }

      &.hidden-error {
        display: none;
      }
    }
  }

  // &.ui-resizable-resizing .card-block {
    //visibility: collapse;
  // }
}
