@import 'canopy-vis-base';

.multi-plot-pane-resize-handles-renderer {
  .handle {
    fill: transparent;
  }

  .column-pane-resize-handle {
    cursor: col-resize;
  }

  .row-pane-resize-handle {
    cursor: row-resize;
  }
}