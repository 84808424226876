@import 'canopy-vis-base';

.parallel-coordinates-viewer-container {

  >svg[clickable=true] {
    cursor: pointer;
  }

  .parallel-coordinates-viewer {
    font: $chart-font;

    .axis-label {
      text-anchor: start;
    }

    .dimension>.brush>.selection {
      stroke: var(--bs-body-color);
    }
  }

  .background path {
    fill: none;
    stroke: #ddd;
    shape-rendering: crispEdges;
    opacity: 0.7;
  }

  .foreground path {
    fill: none;
  }

  .normal-line {
    opacity: 1;
    stroke-width: 1.5px;
  }

  .selected-line {
    opacity: 1;
    // stroke: #000;
    stroke-width: 3.5px;
  }

  .unselected-line {
    opacity: 0.1;
    // color: #000;
    stroke-width: 1.5px;
  }

  .canvas-selected-line {
    fill: none;
    stroke-width: 2px;
    cursor: pointer;
  }

  .canvas-selected-line-outline {
    fill: none;
    stroke-width: 3.5px;
    cursor: pointer;
  }

  .interp-line,
  .interpolation-line {
    opacity: 0.8;
    stroke: red;
    fill: none;
    stroke-width: 1.5px;
    pointer-events: none;
  }

  .tooltip-box {
    fill-opacity: 0.9;
    fill: var(--bs-body-bg);
    stroke: none;
    shape-rendering: crispEdges;
  }

  .flip-switch,
  .zoom-button,
  .clear-brush-button {
    cursor: pointer;

    text {
      font-family: "Font Awesome 6 Free";
      font-size: 12px;
      fill: var(--bs-body-color);
    }

    circle {
      fill: white;
    }
  }

  .clear-brush-button {
    text {
      fill: var(--bs-body-color);
    }
  }

  .disabled-zoom-button,
  .disabled-clear-brush-button {
    display: none;
  }

  .input-dimension path, .input-dimension line {
    stroke: var(--bs-body-color);
  }
  .input-dimension text {
    fill: var(--bs-body-color);
  }
  .dimension {
    .axis {
      font: $chart-font;
      pointer-events: none;

      .tick {
        text {
          text-shadow: 1px 1px 0 var(--bs-body-bg), 1px 0 0 var(--bs-body-bg), 0 -1px 0 var(--bs-body-bg), -1px 0 0 var(--bs-body-bg);
          font-size: 10px;
        }
      }

      path,
      line{
        fill: none;
        shape-rendering: crispEdges;
      }
    }

    .axis-label {
      cursor: move;
    }
  }

  .brush .extent {
    fill-opacity: .2;
    fill: steelblue;
    stroke: #000;
    shape-rendering: crispEdges;
  }

  .mouse-click-helper {
    text {
      fill: gray;
    }
  }

  .job-tooltip {
    fill: var(--bs-body-color);
  }
}
