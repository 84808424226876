#automated-tests {
  padding: 20px;

  #automated-test-buttons button {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }

  .test-item {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 20px;
    margin-top: 2rem;

    .svg-parent {
      display: inline-block;
      border: 1px solid salmon;
      box-sizing: content-box;
    }

    h2 {
      font-size: 1.2rem;
    }

  }

  .test-area-title {
    margin-top: 1rem;
    font-size: 1.3rem;
  }

  .test-chart-container {
    background: var(--bs-body-bg);
    margin-top: 1rem;
    border: 1px solid salmon;
    width: 1000px;
  }

  #canvas-snapshot {
    margin-bottom: 1rem;
  }

  #pixel-ratio-container {
    margin-top: 1rem;
  }

  #test-area-end {
    margin-top: 1rem;
  }

  .padding-rect {
    stroke: #FFCCCC;
    stroke-width: 1px;
    stroke-dasharray: 5, 5;
    z-index: 1000;

    fill: transparent;
  }

  .chart-rect {
    stroke: #FFAAAA;
    stroke-width: 1px;
    stroke-dasharray: 5, 5;
    z-index: 1000;

    fill: transparent;
  }

}
