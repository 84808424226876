.slider-tests {

  .slider-value {
    fill: red;
  }

  .slider-visible-line {
    stroke: #FFCCCC;
    stroke-width: 1px;
  }
}