
.grid-stack .viewer-trackViewer,
.grid-stack .viewer-suspensionViewer {
  .card-buttons {
    display: none;
  }
}

.view-job-navigation-station-component .grid-stack .viewer-trackViewer {
  .card-buttons {
    display: block;
  }
}

.grid-stack .card-buttons-toggle {
  padding: 5px !important;
}
