@import 'canopy-vis-base';

.multi-plot-zoom-renderer {
  font: $chart-font;

  .zoom-brush {
    fill: rgba(var(--bs-body-color-rgb), 0.1);
    stroke: rgba(var(--bs-body-color-rgb), 0.4);
    stroke-width: 1;
    pointer-events: none;

    &.calculating-zoom-brush {
      fill: rgba(0, 0, 200, 0.1);
      stroke: rgba(0, 0, 200, 0.4);
    }
  }
}
