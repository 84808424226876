$blue: #44F;
$red: #F44;

@mixin theme(
$default-color: #888,
$string-color: green,
$number-color: $blue,
$boolean-color: $red,
$null-color: #855A00,
$undefined-color: rgb(202, 11, 105),
$function-color: #FF20ED,
$rotate-time: 100ms,
$toggler-opacity: 0.6,
$toggler-color: #45376F,
$bracket-color: $blue,
$key-color: var(--bs-body-color),
$url-color: $blue ){

  //font-family: monospace;
  &, a, a:hover {
    color: $default-color;
    text-decoration: none;
  }

  .json-formatter-row {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .children {
    &.empty {
      opacity: 0.5;
      margin-left: 1rem;

      &:after { display: none; }
      &.object:after { content: "No properties"; }
      &.array:after { content: "[]"; }
    }
  }

  .value {
  }

  .string {
    color: $string-color;
    white-space: pre;
    word-wrap: break-word;
  }
  .number { color: $number-color; }
  .boolean { color: $boolean-color; }
  .null { color: $null-color; }
  .undefined { color: $undefined-color; }
  .function { color: $function-color; }
  .date { background-color: fade($default-color, 5%); }
  .url {
    text-decoration: underline;
    color: $url-color;
    cursor: pointer;
  }

  .bracket { color: $bracket-color; }
  .key {
    font-weight: bold;
    color: $key-color;
    cursor: pointer;
  }
  .constructor-name {
    cursor: pointer;
  }

  .toggler-link {
    margin-left: 1rem;
  }
  .toggler {
    margin-left: -1rem;
    line-height: 1.2rem;
    font-size: 0.8em;
    vertical-align: middle;
    opacity: 0.6;
    cursor: pointer;

    &:after {
      display: inline-block;
      transition: transform $rotate-time ease-in;
      content: "►";
    }
  }

  // Inline preview on hover (optional)
  > a >.preview-text {
    opacity: 0;
    transition: opacity .15s ease-in;
    font-style: italic;
  }
  &:hover > a > .preview-text {
    opacity: 0.6;
  }

  // Open state
  &.open {
    > .toggler-link .toggler:after{
      transform: rotate(90deg);
    }
    > .children:after {
      display: inline-block;
    }
    > a >.preview-text {
      display: none;
    }
  }
}

// Default theme
.json-formatter-row {
  @include theme();
}
/*
// Dark theme
.json-formatter-dark.json-formatter-row {
  @include theme(
    $default-color: white,
    $string-color: #31F031,
    $number-color: #66C2FF,
    $boolean-color: #EC4242,
    $null-color: #EEC97D,
    $undefined-color: rgb(239, 143, 190),
    $function-color: #FD48CB,
    $rotate-time: 100ms,
    $toggler-opacity: 0.6,
    $toggler-color: #45376F,
    $bracket-color: #9494FF,
    $key-color: #23A0DB,
    $url-color: #027BFF);
}
*/
