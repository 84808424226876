.canopy-slider {
  .handle {
    text {
      font-family: "Font Awesome 6 Free";
      fill: var(--bs-body-color);
    }
  }

  .track-overlay {
    cursor: grab;
  }
}
