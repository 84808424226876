@import 'canopy-vis-base';

.legend-area {
  text {
    font-size: $chart-font-size;
    font-family: $monospace-font-family;
  }

  .legend-label {
    font-family: $chart-font-family;
  }

  .render-channel-icon {
    font-family: "Font Awesome 6 Free";
    &:not(.active-render-channel-icon){
      fill: lighten(gray, 10%);
    }
  }

  .copy-legend-values-area {
    text {
      fill: gray;

      &.copy-legend-values-icon {
        font-family: "Font Awesome 6 Free";
      }
    }

    cursor: pointer;
  }
}
