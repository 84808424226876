@import "awesomplete/awesomplete.css";
@import "canopy-base";

div.awesomplete {
  width: 100%;
}

div.awesomplete > ul {
  background: var(--bs-body-bg);
  z-index: 1000;
}

div.awesomplete > ul > li:hover {
  background: $brand-info;
  color: black;
}

div.awesomplete > ul > li[aria-selected="true"] {
  background: lighten($brand-highlight, 20%);
  color: white;
}

$highlight: rgba(242, 209, 62, 0.5);

div.awesomplete mark {
  background: $highlight;
}

div.awesomplete li:hover mark {
  background: $highlight;
}

div.awesomplete li[aria-selected="true"] mark {
  background: $highlight;
  color: inherit;
}
