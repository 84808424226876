@import 'canopy-vis-base';


.source-labels {
  font: $chart-font;

  .split-source {
    font-family: "Font Awesome 6 Free";
  }

  :only-child>text{
    fill: var(--bs-body-color)
  }
}
