@import "canopy-base";

@mixin validity {
  &.ng-valid {
    border-left: 5px solid $brand-success;
  }

  &.ng-invalid {
    border-left: 5px solid $brand-danger;
  }

  &.ng-pristine {
    border-left: 5px solid var(--bs-secondary-color);
  }
}

.form-control:focus {
  //border-color: inherit;

  @include validity;
}

.form-control{
  @include validity;
}

.btn {
  cursor: pointer;
}

.btn:not(.btn-icon) {
  &>i.fa {
    margin-right: 0.5rem;
  }
}

.btn-icon-only {
  border: 0;
}

.btn-smart{
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-border-radius-lg);

  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  .btn-block + .btn-block {
    margin-top: 5px;
  }

  @include media-breakpoint-up(sm) {
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-border-radius);
    display: inline-block;
    width: inherit;
  }
}

.btn-smart + .btn-smart {
  margin-top: 5px;

  @include media-breakpoint-up(sm) {
    margin-top: inherit;
  }
}

a.btn-menu {
  text-align: left;
  width: fit-content;
}

.btn-xsm {
  padding: 0 0.5rem;
}

// http://www.abeautifulsite.net/whipping-file-inputs-into-shape-with-bootstrap-3/
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  //font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: var(--bs-body-bg);
  cursor: inherit;
  display: block;
}

input.input-warning {
  box-shadow: 0px 0px 5px 2px rgba(255,0,0,0.3);
}
