.toggle-area {
  font: $chart-font;

  .title {
    fill: var(--bs-secondary-color);
  }

  .toggle {
    cursor: pointer;

    rect {
      shape-rendering: crispEdges;
      fill: var(--bs-body-bg);
      stroke: var(--bs-border-color);
      stroke-width: 1;

      &.toggle-selected {
        fill: $shade-color-3;
      }

      &.calculating-toggle-selected {
        fill: rgba(0, 0, 200, 0.4)
      }
    }

    text {
      fill: var(--bs-secondary-color);
    }
  }

}
