@import 'canopy-vis-base';

.multi-plot-data-renderer {
  .cursor-line {
    pointer-events: none;
    stroke: var(--bs-body-color);
  }
  .cursor-point {
    stroke: var(--bs-body-color)
  }
}
