@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_variables-dark.scss";
@import "bootstrap/scss/_mixins.scss";
$content-max-width: 950px;

$content-margin: 0.9375rem;
$main-content-top-margin: $content-margin*2;

$brand-highlight: #063559;
$brand-highlight-2: lighten($brand-highlight, 10%);
$brand-highlight-3: lighten($brand-highlight, 20%);
$link-color: $brand-highlight-3;

$primary:             $brand-highlight-2;
$success:             #8FBF4D; /* #5cb85c */
$info:                #96b2c7;
$warning:             #F2D13E;
$danger:              #D95929; /* #d9534f */

// Backwards compatibility
$brand-primary:       $primary;
$brand-success:       $success;
$brand-info:          $info;
$brand-warning:       $warning;
$brand-danger:        $danger;

$input-border-focus:             $brand-primary; // Default is #66afe9
$input-box-shadow-focus:         rgba(102,175,233,.6);

$card-spacer-x:            1rem;

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;

$modal-md:                    600px;

$btn-transition: none;

//$theme-colors: ();
// $theme-colors: map-merge(
//     (
//       "transparent":    rgba(255, 255, 255, 0),
//     ),
//     $theme-colors
// );

$page-footer-margin: 30rem;
$max-dock-height: 85%;

//Bootstrap 5 adjustments
$btn-white-space: nowrap;
$card-cap-padding-y: 0.75rem;
$link-decoration: none;
$link-hover-decoration: underline;
$table-cell-padding-y: .75rem;
$table-cell-padding-x: .75rem;

$body-bg-dark: $black;
$body-color-dark: #ccc;
//Obstensibly for logo text
//$body-emphasis-color-dark: #ccc;
$border-color-dark: #555;

//Shading is less noticable in dark mode
@include color-mode(light){
  --shading-scaling: 1;
}
@include color-mode(dark){
  --shading-scaling: 2;
}
$shade-color-1: rgba(var(--bs-body-color-rgb), calc(0.03 * var(--shading-scaling)));
$shade-color-2: rgba(var(--bs-body-color-rgb), calc(0.05 * var(--shading-scaling)));
$shade-color-3: rgba(var(--bs-body-color-rgb), calc(0.10 * var(--shading-scaling)));
$card-cap-bg: $shade-color-2;
$small-font-size: .8em;
$header-background: $card-cap-bg;
$filter-border: var(--bs-border-width) solid var(--bs-border-color);
