@import "canopy-base";


.dialog-content-width {
  .modal-dialog {
    max-width: $content-max-width;
    width: inherit;
  }
}

.dialog-inner-scroll {
  .modal-dialog {
    max-width: $content-max-width;
    width: inherit;
    margin: 0 auto;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;

    .modal-content {
      position: relative;
      height: 100%;
    }

    .modal-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 56px;
      @include media-breakpoint-up(sm) {
        height: 60px;
      }

      .modal-title {
        small{
          color: grey;
          margin-left: 0.5rem;
        }
      }
    }

    .modal-body {
      position:absolute;
      top: 56px;
      bottom: 60px;
      left: 0;
      right: 0;
      @include media-breakpoint-up(sm) {
        top: 60px;
        bottom: 66px;
      }
    }

    .modal-footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 60px;
      @include media-breakpoint-up(sm) {
        height: 66px;
      }
    }
  }

  .scroll-area {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    overflow-y: scroll;
    padding: $content-margin;
  }
}

.dialog-full-width {
  .modal-dialog {
    max-width: 98%;
  }
}

.dialog-almost-full-width {
  .modal-dialog {
    max-width: 95%;
  }
}

.dialog-no-footer {
  .modal-dialog {
    .modal-body {
      bottom: 0;
      @include media-breakpoint-up(sm) {
        bottom: 0;
      }
    }
  }
}

.close-button {
  display: inline-block;
  position: absolute;
  right: $content-margin;
  top: 0.75em;
  bottom: 0.75rem;
}

.float-close-button {
  float: right;
}
