@import "canopy-base";
@import "bootstrap";
@import "awesomplete";
@import "jsondiffpatch";

@import "forms";
@import "dialogs";

@import "visualizations/styles/styles";
@import "canopy-vis";

@import "common/json-formatter/json-formatter";
@import "jsondiffpatch/formatters/styles/html.css";
//@import "../node_modules/katex/dist/katex.css";

@import "simulations/configs/json-config-editor/json-editor.service";

@import "worksheets/worksheets";
@import "simulations/configs/comparing/comparing";

html{
  font-size: 13px;
  @include media-breakpoint-up(sm){
    font-size: 14px;
  }
  @include media-breakpoint-up(md){
    font-size: 15px;
  }

  // This set of properties is to disable scrolling on the body and enable it on
  // the application container, so that the scroll bars behave as expected when
  // a worksheet is docked.
  height: 100%;
  body {
    overflow: hidden;
    height: 100%;
    .application-container {
      overflow: auto;
      min-height: 100% - $max-dock-height;
    }
  }
}

.main-content{
  margin: $main-content-top-margin 0;
}

.disable-animations{
  background: #FAFAFF;
}
.disable-animations *,
.disable-animations *:after,
.disable-animations *:before {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

.last-item {
  margin-bottom: 0;
}

.raw-json {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 0.9rem;
}

.button-list {
  list-style-type: none;
  padding-left: 0;

  li {
    margin: 0.5rem 0;
    &:first-child{
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    >a>.fa {
      margin-right: 0.5rem;
    }
  }
}

.icon-cell {
  width: 29px;
}

.debug-information {
  display: none;
}
.disable-animations .debug-information {
  display: block !important;
  max-height: 200px !important;
}

button:focus { outline:0 !important; }

.list-card {
  .list-card-header {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
  }
  .list-card-padded {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
  }
  margin-bottom: 2rem;
}

.overflow-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}


.inline-button-text {

  display: block;
  margin: 1rem 0 0.5rem 0;
  font-weight: bold;
  text-align: center;

  @include media-breakpoint-up(sm) {
    display: inline-block;
    margin: 0.2rem 0.5rem 0 1rem;
    text-align: left;

    &.inline-button-text-left {
      margin-left: 0;
    }
  }
}

.button-menu-hover-target {
  position: relative;
  display: inline-block;
  .button-menu-hovering-area {
    visibility: hidden;
    position:absolute;
    top: -0.5rem;
    left: -0.5rem;
    background: rgba(var(--bs-body-bg-rgb), 0.9);
    padding: 0.5rem;
    border: 1px solid var(--bs-border-color);
    z-index: 1005;
    &>button, &>a, .add-margin {
      margin-top: 10px;
      display:block;

      &:first-child {
        margin-top: 0;
      }
    }

    &.full-width-buttons button {
      width: 100%;
    }
  }
  &:hover>.button-menu-hovering-area {
    visibility: visible;
  }
}


td.button-menu-hover-target {
  display: table-cell;

  &>.button-menu-hovering-area {
    white-space: nowrap;
    &> button {
      display: inline;
      margin-top: 0;
    }
  }
}

.custom-property-values-column {
  position: relative;
  overflow: visible !important;
}
.custom-property-values {
  position: absolute;
  bottom: 0.4rem;
  color: darken(lightgray, 20%);
  line-height: 1.15rem;
  margin-top: 0.2rem;
  &>div {
    height: 1.15rem;
  }
}
.custom-property-names {
  margin-top: 0.2rem;
  margin-bottom: -0.4rem;
  color: darken(lightgray, 20%);
  line-height: 1.15rem;
  &>div {
    height: 1.15rem;
  }
}

.toggle-properties-button{
  margin-left: 0.5rem;
  font-weight: normal;
  color: gray;
  font-size: 0.8rem;
  &:focus {
    text-decoration: none;
  }
}

td {
  .study-type-area, .config-modified-date-area, .job-count-area {
    position: relative;

    .study-sim-version, .config-sim-version, .view-credits-component {
      position: absolute;
      top: -0.8rem;
      left: 0;
      font-size: 0.75rem;
      color: var(--bs-secondary-color);
    }

    .study-sim-version, .config-sim-version {
      a {
        color: lightgray;
        text-decoration: none;
      }

      &.sim-version-warning {
        color: $brand-danger;
        a {
          color: $brand-danger;
        }
      }
    }
  }
}

.select-item-checkbox {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.table-footer-buttons {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.card-header-float-right {
  float: right;
  margin: -0.5rem -0.8rem;

  button {
    min-height: 2.7rem;
  }
}

.modal-dialog-wide {
  width: inherit;
  margin: 0 auto;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  max-width: $content-max-width;

  .modal-title {
    small {
      margin-left: 1rem;
      color: gray;
    }
  }
}

.notes-input {
  min-height: 4rem;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.monospace-text-area {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.filtered-list {
  border: $filter-border;
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}
