@import 'canopy-base';

.comparing {

  $left-indent: 1rem;
  $value-padding: 0.25rem;
  $property-name-padding: 0.75rem;

  $added-color: var(--bs-success-border-subtle);
  $deleted-color: var(--bs-danger-border-subtle);

  .help-text {
    .fa {
      margin-right: 0.25rem;
    }

    color: $gray-600;
    font-style: italic;
    margin-left: 1rem;
  }

  .complex-value {
    display: inline-block;
    margin-left: $left-indent - $value-padding;
    //padding-left: $value-padding;
    padding-right: $property-name-padding;
  }

  .property-name, .array-index {
    font-weight: bold;
    vertical-align: top;
    margin-left: $property-name-padding;
    cursor: pointer;

    position: relative;
    &>.parameter-path {
      display: none;
      //font-size: 0.9rem;
      //font-color: $gray-600;
      //font-family: $font-family-monospace;
    }

    &:hover {
      &>.parameter-path {
        display: inline;
      }
    }
  }
  .parameter-path, .full-name {
    position: absolute;
    left: 0;
    background-color: var(--bs-body-bg);
    border: 1px solid $gray-500;
    padding-left: $value-padding;
    padding-right: $value-padding;
    z-index: 10;
  }

  .object-delta, .array-delta {
    padding-left: $left-indent;
    margin-bottom: $value-padding;
    border-left: 1px solid $gray-100;
    &:hover {
      border-left: 1px solid $gray-200;
    }
  }

  .array-delta-index, .object-delta-property {
    position: relative;
    margin-bottom: $value-padding;
  }

  .value-container {
    &.complex-value-container {
      display: block;
    }
    &:not(.complex-value-container) {
      padding-left: $value-padding;
      padding-right: $value-padding;
      display: inline-block;
      min-height: 10px;
      color: var(--bs-body-color);
    }
  }

  $select-output-value-cursor: pointer;
  @mixin valueBox($color){
    cursor: $select-output-value-cursor;
    &.complex-value-container {
      &>.complex-value {
        background-color: $color;
        border: 1px solid transparent;
      }
    }
    &:not(.complex-value-container) {
      background-color: $color;
      border: 1px solid transparent;
      color: var(--bs-body-color);
    }
  }
  .deleted {
    @include valueBox($deleted-color);
  }

  .added {
    @include valueBox($added-color);
  }

  .added, .deleted {
    position: relative;

    .full-name {
      display: none;
      top: 1.5rem;
      white-space: nowrap;
    }

    &:hover {
      .full-name {
        display: inline;
      }
    }
  }

  @mixin outputValue($name, $color) {
    $output-value-border: 1px solid $color;
    .#{$name}.output-value {
      &.complex-value-container {
        &>.complex-value {
          border: $output-value-border;
        }
      }
      &:not(.complex-value-container) {
        border: $output-value-border;
      }
    }
  }

  @include outputValue(deleted, var(--bs-danger-text-emphasis));
  @include outputValue(added, var(--bs-success-text-emphasis));
  @include outputValue(output-value-key, var(--bs-body-color));

  .key {
    margin-left: 0.5rem;
  }
  .output-value-key {
    display: inline-block;
    vertical-align: middle;
    width: 0.95rem;
    height: 0.95rem;
    margin-bottom: 0.2rem;
  }

  .collapse-button {
    color: $gray-500;
    position: absolute;
    left: -0.5rem;
    top: 0.25rem;
  }

  &>.renderer>.object-delta {
    border-left: none;
    padding-left: 0.5rem;
  }
  &>.object-delta-property {
    &>.property-name {
      margin-left: 0;
    }
    //margin-bottom: 0;
  }

  .textdiff {
    list-style: none;

    .textdiff-location {
      display: inline-block;
      color: $gray-600;
      font-family: $font-family-monospace;
      min-width: 60px;

      .textdiff-line-number {
      }
      .textdiff-char {
      }
    }
    .textdiff-line {
      display: inline-block;

      .textdiff-context {
      }
      .textdiff-added {
        background-color: $added-color;
      }
      .textdiff-deleted {
        background-color: $deleted-color;
      }
    }
  }
}
