@import 'canopy-vis-base';

.contour-viewer-container {

  .contour-viewer {
    font: $chart-font;
  }

  .pane-handle {
    fill: #000;
    opacity: 0;
    cursor: row-resize;
  }

  .pane {
    .line {
      fill: none;
      stroke-width: 1px;
    }

    .grid {
      .tick {
        line {
          opacity: 0.5;

        }
      }

      path {
        stroke-width: 0;
      }
    }
  }

  .dot {
    fill: #fff;
    stroke: #000;
  }

  .zoomArea {
    fill: steelblue;
    opacity: 0.2;
  }

  .grid {
    .domain {
      display: none;
    }

    .tick {
      line {
        stroke: lightgrey;
        opacity: 0.7;
        stroke-dasharray: 1, 2;
      }

      text {
        display: none;
      }

      path {
        stroke-width: 0;
      }
    }
  }

  .axis path,
  .axis line {
    stroke: var(--bs-body-color);
  }

  .axis .tick text,
  .axis .axis-label,
  .legend .legend-label,
  .legend .legend-units,
  .xAxes .label {
    font: $chart-font;
    fill: var(--bs-body-color);
  }

  .legend .legend-value,
  .x-legend .legend-value {
    font-size: $chart-font-size;
    font-family: $monospace-font-family;
  }

  .axis path,
  .axis line {
    fill: none;
    shape-rendering: crispEdges;
  }

}
