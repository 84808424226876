@import 'canopy-vis-base';

.orbit-controls {
  font: $chart-font;

  text {
    //text-anchor: end;
    fill: gray;
  }
}
