@import 'canopy-vis-base';

.viewer-3d-controls-container {
  position: absolute !important;
  top:0;
  left: 0;

  // This allows us to handle mouse events in the 3D part of the viewer
  // unless the mouse even is in an area where 2D controls are being rendered.
  // e.g. double click to reset camera.  Without this then we need the listener
  // on the parent of both canvases, and then double clicking on the 2D controls resets
  // the camera.
  pointer-events: none;
  &>svg {
    &>* {
      pointer-events: all;
    }
  }
}