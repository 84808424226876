
.json-editor {

  // BOOTSTRAP 3 Compatibility

  .card {
    margin-left: 0.5rem;
    margin-right: -1rem;
    padding: 0 1rem 0 1rem;
    border: 0 solid $header-background;
    border-left-width: 1px;
    border-radius: 3px;
    background: rgba(var(--bs-body-color-rgb), calc(0.01  * var(--shading-scaling)));
  }

  p {
    font-size: 0.9rem;
  }

  .property-selector {
    background-color: var(--bs-body-bg);
    label {
      font-size: 0.9rem !important;

      input {
        margin-top: 0 !important;
      }
    }
  }

  .h3, b {
    font-size: 1.2rem;
  }

  .h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .hover-button-group-container {
    > :is(div, span):has(>.hover-button-group){
      display: inline-flex;
      position: relative;
      align-items: center;
      justify-content: start;
      > :is(div, span).hover-button-group {
        display: none;
        position: absolute;
        white-space: nowrap;
        padding: 0px 10px;
        left: 100%;
        z-index:2;
      }
    }
    &:hover > :is(div, span):has(>.hover-button-group) > :is(div, span).hover-button-group {
      display: inline-block;
    }
  }

  .form-group{
    margin-bottom: 0.5rem !important;
  }

  label {
    font-weight: bold !important;
    font-size: 1.2rem !important;
  }

  .units-addon {
    cursor: pointer;
    min-width: 60px;
    text-align: center;
    display: inline-block;
  }

  .help-link-container {
    cursor: pointer;

    &, &:hover, &:active, &:focus {
      text-decoration: none;
      color: var(--bs-body-color);
    }

    .help-tooltip {
      visibility: hidden;
    }
    &:hover .help-tooltip {
      visibility: visible;
    }
  }

  .help-tooltip {
    border: 1px solid $input-group-addon-border-color;
    border-radius: 3px;
    padding: 0.2rem 0.4rem;
    display: inline-block;
    white-space: nowrap;

    &:before {
      font-family: "Font Awesome 6 Free";
      content: "\f08e";
      margin-right: 0.4rem;
      font-size: 0.9rem;
    }
  }

  .help-non-addon {
    position: absolute;
    right: $content-margin;
    top: 0;
    .fa {
      margin: 0 $content-margin;
    }
    .help-tooltip {
      position: absolute;
      right: 3*$content-margin;
      top: -5px;
    }
  }

  .row > div > div > div[data-schematype="object"] > .help-non-addon {
    right: 0;
  }

  .help-addon {
    position: relative;
    .help-tooltip {
      position: absolute;
      right: 0;
      bottom: 2.5rem;
    }
  }

  .btn-sm {
    height: 2rem;
    padding: 0.2rem 1rem;
    margin-bottom: 1rem;
    margin-bottom: 0;
  }

  .btn:disabled {
    opacity: 1;
  }

  textarea {
    min-height: 4rem !important;
  }

  .json-editor-btn-add {
    .fa {
      color: green;
    }
  }

  .json-editor-btn-delete {
    .fa {
      color: red;
    }
  }

  select {
    height: 30px !important;
    padding: 0 30px 0 6px !important;
  }

  input[type="checkbox"] {
    margin-top: 0.4rem;
    margin-right: 0.4rem;
  }

   // This keeps the inputs full width on smaller screens.
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    position: relative;
  }

  .custom-editor-hidden {
    display:none;
  }

  .sub-tree-button-hidden {
    display: none;
  }

  .select-hidden {
    display: none !important;
  }

  .decrypt-failed-button {
    background: red;
    &:active, &:hover {
      background: red;
    }
  }

  .table-responsive{
    overflow-x: initial;
  }
}

// This will also need 'input-group-sm' class on json-editor.service.ts/getFormControl.
.canopy-compact .json-editor {
  .form-control {
    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    // line-height: $input-line-height-sm;
    @include border-radius($input-border-radius-sm);
  }

  p, .help-block {
    font-size: 0.8rem;
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 0 !important;
  }

  label {
    font-size: 1rem !important;
    margin-bottom: 0 !important;
  }

  .btn-default {
    height: 1.8rem;
    font-size: 0.9rem;
  }

  select {
    font-size: 0.9rem;
  }
}
