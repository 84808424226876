.source-labels-tests {

  .labels-value {
    fill: red;
  }

  .legend-rect {
    stroke: #FFCCCC;
    stroke-width: 1px;

    fill: #FFF5F5;
  }
}